import axios from "./index";
export default class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  async upload() {
    const image = await this.loader.file;
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("images", image);
      axios
        .post("/v1/blog/upload", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve({
            default: response.data.data[0].url,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  abort() {
    // Abort the upload process if needed
  }
}
